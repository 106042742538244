/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */



nav > .nav.nav-tabs{

  border: none;
    color:#fff;
    background:#272e38;
    border-radius:0;

}
nav > div a.nav-item.nav-link,
nav > div a.nav-item.nav-link.active
{
  border: none;
    padding: 18px 25px;
    color:#fff;
background: linear-gradient(to left, #f4a736 0%, #f37535 100%);    border-radius:0;
}

nav > div a.nav-item.nav-link.active:after
 {
  content: "";
  position: relative;
  bottom: -60px;
  left: -10%;
  border: 15px solid transparent;
  border-top-color: #f4a636 ;
}
.tab-content{
  background: #fdfdfd;
    line-height: 25px;
    border: 1px solid #ddd;
    border-top:5px solid #f4a636;
    padding:30px 25px;
}

nav > div a.nav-item.nav-link:hover,
nav > div a.nav-item.nav-link:focus
{
  border: none;
    border-radius:0;
    transition:background 0.20s linear;
}

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99999999;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}


.as_footer_widget .as_contact_list li {
  padding-left: 0 !important;
  display: flex !important;
  align-items: baseline !important;
  list-style-type: none!important;
}
.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #ff4500!important;
  color: #ff4500!important;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

/*.content {
  margin-left: 75px;
  font-size: 30px;
}
*/



   
h2 {
    color: #000;
    font-size: 26px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    margin: 30px 0 60px;
}
h2::after {
    content: "";
    width: 100px;
    position: absolute;
    margin: 0 auto;
    height: 4px;
    border-radius: 1px;
    background: #ffbf6a;
    left: 0;
    right: 0;
    bottom: -20px;
   }
.carousel {
    /* margin: 50px auto; */
    /* padding: 0 70px;
}
.carousel .item {
    color: #747d89;
    min-height: 325px;
    text-align: center;
    overflow: hidden;
}
.carousel .thumb-wrapper {
   padding: 25px 15px;
background: #fff;
border-radius: 6px;
text-align: center;
position: relative;
box-shadow: 1px 3px 6px 2px rgba(0,0,0,0.2);
}
.carousel .item .img-box {
    height: 120px;
    margin-bottom: 20px;
    width: 100%;
    position: relative;
}
.carousel .item img {   
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
}
.carousel .item h4 {
    font-size: 18px;
}
.carousel .item h4, .carousel .item p, .carousel .item ul {
    margin-bottom: 5px;
}
.carousel .thumb-content .btn {
    color: #ffbf6a;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    background: none;
    border: 1px solid #ffbf6a;
    padding: 6px 14px;
    margin-top: 5px;
    line-height: 16px;
    border-radius: 20px;
}

.thumb-content {
  text-align: center;
}



.carousel .thumb-content .btn:hover, .carousel .thumb-content .btn:focus {
    color: #fff;
    background: #ffbf6a;
    box-shadow: none;
}
.carousel .thumb-content .btn i {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
}
.carousel .item-price {
    font-size: 13px;
    padding: 2px 0;
}
.carousel .item-price strike {
    opacity: 0.7;
    margin-right: 5px;
}
 .carousel-control-next {
    height: 44px;
    width: 40px;
    background: #ffb046;    
    margin: auto 0;
    border-radius: 4px;
    opacity: 0.8;
}

a.carousel-control-prev,a.carousel-control-next {
  height: 44px!important;
  width: 40px !important;
  background: #ffb046 !important;
  margin: auto 0 !important;
  border-radius: 4px !important;
  opacity: 0.8 !important;
}
.carousel-control-prev:hover, .carousel-control-next:hover {
    background: #ffb046;
    opacity: 1;
}
.carousel-control-prev i, .carousel-control-next i {
    font-size: 36px;
    position: absolute;
    top: 50%;
    display: inline-block;
    margin: -19px 0 0 0;
    z-index: 5;
    left: 0;
    right: 0;
    color: #fff;
    text-shadow: none;
    font-weight: bold;
}
.carousel-control-prev i {
    margin-left: -2px;
}
.carousel-control-next i {
    margin-right: -4px;
}       
.carousel-indicators {
    bottom: -50px;
}
.carousel-indicators li, .carousel-indicators li.active {
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 50%;
    border: none;
}
.carousel-indicators li {   
    background: rgba(0, 0, 0, 0.2);
}
.carousel-indicators li.active {    
    background: rgba(0, 0, 0, 0.6);
}
.carousel .wish-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    cursor: pointer;
    font-size: 16px;
    color: #abb0b8;
}
.carousel .wish-icon .fa-heart {
    color: #ff6161;
} */ 
}
.star-rating li {
    padding: 0;
}
.star-rating i {
    font-size: 14px;
    color: #ffc000;
}

.fea{background:url(https://hucpl.com/Astro_Aruna/assets/images/bg2.jpg);
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
  
}

/* @keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
} */

.blinking {
  position: relative;
  top: -53px;
  right: -79px;
}


.button {
  background-color: #004A7F;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
@-webkit-keyframes glowing {
  0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #f58034; -webkit-box-shadow: 0 0 40px #f58034; }
  100% { background-color: #f58034; -webkit-box-shadow: 0 0 3px #f58034; }
}

@-moz-keyframes glowing {
  0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #f58034; -moz-box-shadow: 0 0 40px #f58034; }
  100% { background-color: #f58034; -moz-box-shadow: 0 0 3px #f58034; }
}

@-o-keyframes glowing {
  0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #f58034; box-shadow: 0 0 40px #f58034; }
  100% { background-color: #f58034; box-shadow: 0 0 3px #f58034; }
}

@keyframes glowing {
  0% { background-color: #f58034; box-shadow: 0 0 3px #f58034; }
  50% { background-color: #f58034; box-shadow: 0 0 7px #f58034; }
  100% { background-color: #f58034; box-shadow: 0 0 3px #f58034; }
}




.sliderbanner {
 border: 5px solid #e7e7e733;
padding: 7px 0px;
border-radius: 4px;
background: #151515ad;
}



.as_booking_section {
  border-bottom: 0px solid #e7e7e7;
  margin-bottom: 37px;
}

.as_booking_section ul.as_total_amount {
  border-right: 0px solid #e7e7e7;
  padding-right: 45px;
  margin-right: 15px;
}

.as_widget.as_category_widget ul li a {
  color: #000;
  text-decoration: none;
}

.as_widget.as_workinghours_widget ul li a {
  color: black;
  text-decoration: none;
}
.form-control {
 
  border-radius: 2.25rem;
}

.form-control-dropdown-toggle{
  border-radius: 2.25rem; 
  width:100%;
  height:60%;
}
.fa.fa-search {
  color: #ff6f00;
}

.as_menu ul li a {
  text-decoration: none;
}

.as_menu >ul >li {
  display: inline-flex;
  text-transform: capitalize;
  /* margin: 0 30px; */
  color: #ffffff;
  position: relative;
}

.as_sign_box.text-center a {
  text-decoration: none;
  color: black;
}

.thumb-content h4 {
  color: black;
  font-family: cursive;
}

.as_blog_detail ul li a {
  color: black;
}

.as_blog_detail ul li a {
  color: black;
}

.as_blog_detail:hover ul li a {
  text-decoration: none;
}

.as_blog_detail a {
  color: black;
}

.as_blog_detail:hover a {
   text-decoration: none;
}

.as_contact_list li p a {
  color: white !important;
  
}

.as_footer_widget ul li a {
  color: white;
}

.as_submenu {
  background: linear-gradient(to left, #f4a736 0%, #f37535 100%);}


  .as_submenu li a {
  color: white !important;
}

.txt {
  text-align: justify;
}

.mb50 {
  margin-bottom: 50px;
}

.txtbox {
  position: relative;
  max-width: 800px;
margin: 13px 0px;
}

.txtbox img {vertical-align: middle;}

.txtbox .content {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
padding: 0px 7px;
font-size: 14px;
}

.as_blog_wrapper {
  /* background-image: url('Assets/images/bg3.jpg'); */
}
.mart{
    margin-top: 30px;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,    #f4a736 0%,    #fff 29%,    #f37535 67%,    #f37535 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.slick-dots {
  
  text-align: center;
}


.slick-prev.slick-arrow {
  background: #f4a736;
  border: 0px;
  color: white;
  font-size: 17px;
  padding: 3px 15px;
  border-radius: 3px;
    margin: 7px 0px;
}

.slick-next.slick-arrow {
  background: #f4a736;
  border: 0px;
  color: white;
  font-size: 17px;
  padding: 3px 15px;
  border-radius: 3px;
  margin: 10px 0px;
}


.testimonial_area {
  display: block;
  overflow: hidden;
  padding-left: 200px;
  padding-top: 220px;
  padding-bottom: 100px;
  position: relative;
  /* Jupitar Nave */
}
.testimonial_area h4 {
  font: 700 18px/1 "Niramit", sans-serif;
  color: #282331;
  padding-bottom: 53px;
  text-transform: uppercase;
}
.testimonial_area h2 {
  font: 400 52px/62px "Niramit", sans-serif;
  color: #282331;
  padding-bottom: 70px;
}
.testimonial_area .testimonial_slide {
  padding-left: 170px;
  /* Control */
}
.testimonial_area .testimonial_slide .testimonial_content {
  padding-left: 220px;
  z-index: 1;
}
.testimonial_area .testimonial_slide .testimonial_content:before {
  content: "\f102";
  position: absolute;
  left: 0;
  top: 0;
  color: #faf7ff;
  font-size: 205px;
  font-family: Flaticon;
  font-style: normal;
  line-height: 1;
  z-index: -1;
}
.testimonial_area .testimonial_slide .testimonial_content p {
  font: 400 20px/31px "Niramit", sans-serif;
  color: rgba(40, 35, 49, 0.8);
  max-width: 800px;
  padding-top: 30px;
}
.testimonial_area .testimonial_slide .testimonial_content .media {
  padding: 85px 0;
}
.testimonial_area .testimonial_slide .testimonial_content .media img {
  height: 82px;
  width: 82px;
  border-radius: 50%;
  max-width: 100%;
  border: 6px solid #e5e6fa;
  box-shadow: 0px 45px 45px 0px rgba(147, 147, 147, 0.35);
}
.testimonial_area .testimonial_slide .testimonial_content .media .media-body {
  padding-left: 30px;
}
.testimonial_area .testimonial_slide .testimonial_content .media .media-body a {
  display: block;
  font: 700 21px/1 "Niramit", sans-serif;
  color: #382c4d;
  padding-top: 10px;
}
.testimonial_area .testimonial_slide .testimonial_content .media .media-body a:hover, .testimonial_area .testimonial_slide .testimonial_content .media .media-body a:focus {
  color: #8f49f9;
}
.testimonial_area .testimonial_slide .testimonial_content .media .media-body h6 {
  font: 400 16px/1 "Niramit", sans-serif;
  color: #8f49f9;
  padding-top: 20px;
}
.testimonial_area .testimonial_slide .nav_control {
  display: block;
  overflow: hidden;
  margin-left: 500px;
  padding-top: 75px;
}
.testimonial_area .testimonial_slide .control-prev {
  color: #8f49f9;
  line-height: 68px;
  width: 84px;
  display: inline-block;
  text-align: right;
  padding-right: 15px;
  background: #fff;
  position: relative;
}
.testimonial_area .testimonial_slide .control-prev:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-style: solid;
  border-width: 68px 30px 0 0;
  border-color: #fff transparent transparent transparent;
}
.testimonial_area .testimonial_slide .control-prev i:before {
  font-size: 43px;
  line-height: 68px;
  display: block;
  font-weight: 300;
}
.testimonial_area .testimonial_slide .control-prev:hover, .testimonial_area .testimonial_slide .control-prev:focus {
  background: #8f49f9;
  color: #fff;
}
.testimonial_area .testimonial_slide .control-next {
  color: #fff;
  line-height: 68px;
  width: 84px;
  display: inline-block;
  padding-left: 15px;
  background: #8f49f9;
  position: relative;
}
.testimonial_area .testimonial_slide .control-next:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  border-style: solid;
  border-width: 0 30px 68px 0;
  border-color: transparent #fff transparent transparent;
}
.testimonial_area .testimonial_slide .control-next i:before {
  font-size: 43px;
  line-height: 68px;
  display: block;
  font-weight: 300;
}
.testimonial_area .indicators {
  position: absolute;
  top: 0;
  right: 30px;
  border-radius: 50%;
  width: 705px;
  height: 705px;
  display: block;
  margin-top: 200px;
}
.testimonial_area .indicators li {
  position: absolute;
  display: block;
  transition: all 300ms linear 0s;
}
.testimonial_area .indicators li img {
  border-radius: 50%;
  border: 6px solid #e5e6fa;
  box-shadow: 0px 45px 45px 0px rgba(147, 147, 147, 0.35);
  cursor: pointer;
  z-index: 1;
}
.testimonial_area .indicators li:hover, .testimonial_area .indicators li:focus {
  border-color: #8f49f9 !important;
}
.testimonial_area .indicators .testimonial_1 {
  top: 0;
  right: 0;
  border: 2px solid #e5e6fa;
  border-radius: 50%;
  width: 705px;
  height: 705px;
}
.testimonial_area .indicators .testimonial_1 img {
  animation: rotation 60s linear infinite;
  transform-origin: -290px 80px;
  top: 270px;
  position: absolute;
  right: -70px;
}
.testimonial_area .indicators .testimonial_1:before {
  content: "";
  border: 3px solid #d4b4fe;
  border-radius: 50%;
  box-shadow: 0px 18px 58px 0px rgba(82, 82, 82, 0.35);
  height: 19px;
  width: 19px;
  display: block;
  animation: rotation 55s linear infinite;
  transform-origin: -342px 10px;
  top: 340px;
  position: absolute;
  right: -10px;
}
.testimonial_area .indicators .testimonial_1:after {
  content: "";
  border: 3px solid #d4b4fe;
  border-radius: 50%;
  box-shadow: 0px 18px 58px 0px rgba(82, 82, 82, 0.35);
  height: 19px;
  width: 19px;
  display: block;
  animation: rotation 100s linear infinite;
  transform-origin: -342px 10px;
  top: 340px;
  position: absolute;
  right: -10px;
}
.testimonial_area .indicators .testimonial_2 {
  border: 1px solid #e5e6fa;
  border-radius: 50%;
  width: 607px;
  height: 607px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}
.testimonial_area .indicators .testimonial_2 img {
  animation: rotation 35s linear infinite;
  transform-origin: -230px 65px;
  top: 230px;
  position: absolute;
  right: -60px;
}
.testimonial_area .indicators .testimonial_3 {
  border: 1px solid #f4f4fd;
  border-radius: 50%;
  width: 494px;
  height: 494px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}
.testimonial_area .indicators .testimonial_3 img {
  animation: rotation 20s linear infinite;
  transform-origin: -170px 75px;
  bottom: 190px;
  position: absolute;
  right: -55px;
}
.testimonial_area .indicators .testimonial_4 {
  border: 1px solid #f4f4fd;
  border-radius: 50%;
  width: 365px;
  height: 365px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}
.testimonial_area .indicators .testimonial_4 img {
  animation: rotation 50s linear infinite;
  transform-origin: -125px 75px;
  top: 100px;
  position: absolute;
  right: -30px;
}
.testimonial_area .indicators .round_shap {
  border: 3px solid #d4b4fe;
  border-radius: 50%;
  box-shadow: 0px 20px 52px 0px rgba(176, 124, 255, 0.35);
  width: 27px;
  height: 27px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.ta_2 {
  padding-top: 120px;
}
.ta_2 .testimonial_slide {
  padding-left: 130px;
}
.ta_2 .testimonial_slide .testimonial_content {
  box-shadow: 0px 20px 50px 0px rgba(238, 228, 253, 0.75);
  background: #fff;
  max-width: 1047px;
  margin: 40px;
  margin-top: 15px;
  padding-top: 20px;
  z-index: 999;
  position: relative;
}
.ta_2 .testimonial_slide .testimonial_content:before {
  font-size: 160px;
  left: 35px;
  top: 50px;
}
.ta_2 .testimonial_slide .testimonial_content .media {
  padding-bottom: 70px !important;
}
.ta_2 .testimonial_slide .nav_control {
  padding-top: 40px;
}
.ta_2 .indicators {
  margin-top: 100px;
  z-index: -1;
}

.ta_4 {
  padding-bottom: 70px;
}
@media (max-width: 1610px) {.testimonial_area {
    padding-left: 15px;
    padding-right: 15px;
  }
  .testimonial_area .testimonial_slide {
    padding-left: 0;
  }}
  @media (max-width: 1440px) {
  .testimonial_area .testimonial_slide .testimonial_content {
    padding-left: 30px;
  }
  .testimonial_area .testimonial_slide .testimonial_content:before {
    font-size: 80px;
  }
  .testimonial_area .testimonial_slide .nav_control {
    margin: 0;
    padding: 0;
  }

  .ta_2 .testimonial_slide .testimonial_content:before {
    top: 20px;
  }
}
@media (max-width: 1199px) {
  .testimonial_area {
    padding-top: 80px;
  }
  .testimonial_area h4 {
    padding-bottom: 20px;
  }
  .testimonial_area h2 {
    padding-bottom: 30px;
  }
  .testimonial_area .indicators {
    position: relative;
    margin: 0;
    right: 0;
    left: 50px;
  }
}
@media (max-width: 767px) {
  .testimonial_area .indicators {
    display: none;
  }}
  @media (max-width: 575px) {.testimonial_area h4 {
    font-size: 16px;
  }
  .testimonial_area h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .testimonial_area .testimonial_slide .testimonial_content p {
    font-size: 18px;
  }
  .testimonial_area .testimonial_slide .testimonial_content .media {
    padding-top: 40px;
  }}
@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}

.fa.fa-home {
  color: #ffa843 !important;
  font-size: 22px;
    position: relative;
  top: 4px;
}


.breadcrumb li a {
  color: white;
}


.as_banner_img .as_hand_bg {
  animation: spin 150s infinite linear;
  -webkit-animation: spin 150s infinite linear;
  -moz-animation: spin 150s infinite linear;
  display: inline-block;
}

.as_banner_detail.sliderlefttext {
  display: none;
}

.hr{cursor: pointer;}

.phone-call {
width: 65px;
height: 67px;
left: 1489px;
bottom: 128px;
background: #ffa843;
position: fixed;
text-align: center;
color: #ffffff;
cursor: pointer;
border-radius: 50%;
z-index: 99;
display: inline-block;
line-height: 65px;
float: right !important;
}

.phone-call::before {
 position: absolute;
content: " ";
z-index: -1;
top: -6px;
left: -6px;
background-color: #fff;
width: 80px;
height: 80px;
  border-radius: 100%;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  opacity: 3.4;
  -webkit-animation: pulse 1s ease-out;
  animation: pulse 1.8s ease-out;
    animation-iteration-count: 1;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(0.3);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale(0.6);
        opacity: .6;
    }
    75% {
        -webkit-transform: scale(0.9);
        opacity: .3;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    25% {
        transform: scale(0.3);
        opacity: 1;
    }
    50% {
        transform: scale(0.6);
        opacity: .6;
    }
    75% {
        transform: scale(0.9);
        opacity: .3;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}


.slick-prev.slick-arrow {
  background: #f4a736;
  border: 0px;
  color: white;
  font-size: 17px;
  padding: 3px 15px;
  border-radius: 3px;
  margin: 7px 0px;
  position: relative;
  top: 317px;
 left: 428px;
}

.slick-next.slick-arrow {
  background: #f4a736;
  border: 0px;
  color: white;
  font-size: 17px;
  padding: 3px 15px;
  border-radius: 3px;
  margin: 10px 0px;
  position: relative;
  left: 555px;
}

.as_footer_widget>p {
  margin: 18px 0 20px !important;
  font-size: 14px !important;
}

@media screen and (min-device-width: 300px) and (max-device-width: 700px) { 
    .icon-bar{ display: none; }
}

@media screen and (min-device-width: 300px) and (max-device-width: 700px) { 
    .phone-call{ width: 65px;
height: 67px;
left: 13px;
bottom: 128px;
background: #ffa843;
position: fixed;
text-align: center;
color: #ffffff;
cursor: pointer;
border-radius: 50%;
z-index: 99;
display: inline-block;
line-height: 65px;
float: right !important; }
}